// config.js
const hostname = window.location.hostname;

let API_BASE_URL;

if (hostname === 'localhost') {
  // Local development
  API_BASE_URL = process.env.REACT_APP_DEV_API_BASE_URL || 'http://localhost:3001/api';
} else if (hostname.startsWith('dev.')) {
  // Development environment for any subdomain of qsells.net
  API_BASE_URL = `http://${hostname}:3001/api`; // Development API with port 3001
} else {
  // Production environment for other domains
  API_BASE_URL = `https://${hostname}:3000/api`; // Production API with port 3000
}

console.log("Current hostname:", hostname);
console.log("API Base URL:", API_BASE_URL);

export default API_BASE_URL;
